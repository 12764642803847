<template>
  <div class="dashboard">

      <el-row :gutter="20" class="mb-20">
          <el-col :span="6">
            <el-card @click="toLink('/credits/main')" style="cursor: pointer;">
                <div class="item-card-data">
                    <div class="grid-cont">
                        <p style="height: 44px">{{ $t('data.dashboard.remainingCredits') }} | {{ $t('data.dashboard.issuedPresent') }}</p>
                        <p class="grid-num">{{branchCreditData.credit}} | {{branchCreditData.can_issue_limit}}</p>
                    </div>
                </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card @click="toLink('/ecert/approval')" style="cursor: pointer;">
                <div class="item-card-data">
                    <div class="grid-cont">
                        <p style="height: 44px">{{ $t('data.dashboard.pendingRequests') }}</p>
                        <p class="grid-num">{{branchCreditData.pend_cert_request}}</p>
                    </div>
                </div>
            </el-card>
          </el-col>
          <!-- this.$store.getters.isManagerAuth -->
          <el-col :span="12" v-if="this.$store.getters.isAdminAuth">
              <el-card>
                <div class="credits-card">
                    <div class="credits-card-text">
                        <p style="height: 44px">{{ $t('data.dashboard.buyCreditsIssues') }}</p>
                        <router-link to="/credits/pricing"><el-button type="primary">{{ $t('data.dashboard.topUp') }}</el-button></router-link>
                    </div>
                    <div class="credits-card-img">
                        <img :src="banners" alt="">
                    </div>
                </div>
            </el-card>
          </el-col>
      </el-row>

      <el-card class="mb-20">
          <el-row :gutter="20">
              <el-col :span="24" class="mb-20">
                <el-select v-model="period" placeholder="Select Period" @change="periodChange">
                    <el-option :label="$t('data.dashboard.days')" value="week"></el-option>
                    <el-option :label="$t('data.dashboard.month')" value="month"></el-option>
                    <el-option :label="$t('data.dashboard.year')" value="year"></el-option>
                </el-select> 
                <el-button type="primary" style="margin-left: 10px;" @click="excelExport">{{ $t('data.dashboard.exportExcel') }}</el-button>
              </el-col>

              <el-col class="mb-30" :span="6" v-for="(item, index) in certStats" :key="index">
                    <el-card shadow="never">
                        <div class="item-card-data">
                            <div class="grid-cont">
                                <p style="height: 44px">{{ item.name }}</p>
                                <p class="grid-num">{{item.val}}</p>
                            </div>
                        </div>
                    </el-card>
              </el-col>

              <el-col :span="12">
                  <div class="item-chart">
                        <div id="report"
                            :style="{ width: '100%', height: '360px' }" style="margin: 30px auto;"></div>
                  </div>
                  
              </el-col>

              <el-col :span="12">
                    <div class="item-chart">
                        <div id="certificate"
                            :style="{ width: '100%', height: '360px' }" style="margin: 30px auto;"></div>
                    </div>
              </el-col>
              

              <el-col class="mt-30" :span="24">
                    <div class="item-chart">
                        <div id="yearly"
                            :style="{ width: '100%', height: '600px' }" style="margin: 30px auto;"></div>
                    </div>
              </el-col>
          </el-row>
      </el-card>

      <el-row :gutter="20">
          <el-col :span="12">
              <el-card>
                <template #header>
                    <div class="card-header">
                        <span>{{ $t('data.dashboard.pendingApplications') }}</span>
                    </div>
                </template>
                <el-table
                    :data="tableData"
                    height="268"
                    style="width: 100%">
                    <el-table-column
                    :label="$t('data.approval.table.photo')"
                    width="80">
                        <template #default="scope">
                        <img :src="scope.row.member.avatar" alt="" class="table-user-photo">
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="member.name"
                        :label="$t('data.approval.table.userName')">
                    </el-table-column>
                    <el-table-column
                        prop="template_cert.topic"
                        :label="$t('data.approval.table.certTopic')">
                    </el-table-column>
                    <el-table-column
                        prop="audit_at"
                        :label="$t('data.approval.table.requestedTime')">
                    </el-table-column>
                </el-table>
                <div class="mt-20" align="center">
                    <router-link to='/ecert/approval'><el-button type="primary">{{ $t('data.dashboard.viewAllApplications') }}</el-button></router-link>
                </div>
              </el-card>
          </el-col>
          <el-col :span="12">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>{{ $t('data.dashboard.notifications') }}</span>
                        </div>
                    </template>
                    <div class="notifications-list">
                        <ul>
                            <li v-for="(item,index) in notifications" :key="index">
                                <el-link class="notifications-list-text" v-if="item.type === 3" :underline="false" :href="'/ecert/detail/'+item.modelable_id">
                                    {{item.title}}
                                </el-link>
                                <p v-else :underline="false" class="notifications-list-text">
                                    {{item.title}}
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div align="center">
                        <el-button type="primary" @click="toMore()">{{ $t('data.public.viewMore') }}</el-button>
                    </div>
                </el-card>
          </el-col>
      </el-row>

      
    <el-card class="mt-20">
        <template #header>
            <div class="card-header">
                <span>{{ $t('data.dashboard.recentIssuedCerts') }}</span>
            </div>
        </template>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-table 
                    :data="tableRecent"
                    @row-click="overallClick"
                    style="width: 100%">
                    <el-table-column
                    :label="$t('data.issuedCerts.table.photo')"
                    width="80">
                        <template #default="scope">
                        <img :src="scope.row.member.avatar" alt="" class="table-user-photo">
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="member.name"
                        :label="$t('data.issuedCerts.table.applicantName')">
                    </el-table-column>
                    <el-table-column
                        prop="number"
                        :label="$t('data.issuedCerts.table.eCertNumber')">
                    </el-table-column>
                    <el-table-column
                        prop="topic"
                        :label="$t('data.issuedCerts.table.certTopic')">
                    </el-table-column>
                    <el-table-column
                        prop="issue_date"
                        :label="$t('data.issuedCerts.table.certIssueDate')">
                    </el-table-column>
                    <el-table-column
                        :label="$t('data.issuedCerts.table.status')">
                        <template #default="scope">
                            <p v-if="scope.row.status === 1">{{ $t('data.status.valid') }}</p>
                        <p v-else-if="scope.row.status === 2">{{ $t('data.status.expired') }}</p>
                        <p v-else-if="scope.row.status === 3">{{ $t('data.status.cancel') }}</p>
                        <p v-else-if="scope.row.status === 4">{{ $t('data.status.change') }}</p>
                        <p v-else-if="scope.row.status === 5">{{ $t('data.status.amended') }}</p>
                        <p v-else-if="scope.row.status === 6">{{ $t('data.status.remark') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="issuer.name"
                        :label="$t('data.issuedCerts.table.approvedBy')">
                    </el-table-column>
                    <el-table-column
                        prop="approved_at"
                        :label="$t('data.issuedCerts.table.approvedTime')">
                    </el-table-column>
                </el-table>
                <div class="mt-30" align="center">
                    <router-link to='/ecert/issued-certs'><el-button type="primary">{{ $t('data.dashboard.viewAllIssuedCerts') }}</el-button></router-link>
                </div>
            </el-col>
        </el-row>
    </el-card>

  </div>
</template>

<script>
import { yearCert,statsCert,branchCredit,membersCertApplies,certsList,branchNotifications,membersExport,getBase } from '../../api/index'
export default {
    data(){
        return {
            banners: '',
            yearCertData: {
                name: [],
                count: []
            },
            reportChartData: {
                name: [],
                count: []
            },
            certificateChartData: {
                name: [],
                count: []
            },
            branchCreditData:{
                credit: '',
                can_issue_limit: '',
                pend_cert_request: ''
            },
            certStats: [{
                name: this.$t('data.dashboard.userNumber'),
                val: ''
            },{
                name: this.$t('data.dashboard.issuedNumber'),
                val: ''
            },{
                name: this.$t('data.dashboard.usedCredit'),
                val: ''
            },{
                name: this.$t('data.dashboard.viewNumber'),
                val: ''
            }],
            tableData: [],
            tableRecent: [],
            period: 'year',
            notifications: []
        }
    },
    mounted() {
        this.initPageData();
        this.initTableData();
    },
    components:{
    },
    methods: {
       initPageData(){
          let apiData = {
              time_type: this.period
          }
          yearCert({apiData}).then( res => {
              this.yearCertData.name = [],
              this.yearCertData.count = [],
              res.data.forEach(item => {
                  this.yearCertData.name.push(item.name);
                  this.yearCertData.count.push(item.count);
              });
              this.yearlyChart();
          })
          statsCert({apiData}).then( res => {
                this.reportChartData.name = [],
                this.reportChartData.count = [],
                this.certificateChartData.name = [],
                this.certificateChartData.count = []
                res.data.branch_stat.forEach(item => {
                    this.reportChartData.name.push(item.name);
                    this.reportChartData.count.push(item.issue_count);
                })
                res.data.template_cert_stat.forEach(item => {
                    this.certificateChartData.name.push(item.topic);
                    this.certificateChartData.count.push(item.issue_count);
                })
                this.certStats[0].val = res.data.member_count;
                this.certStats[1].val = res.data.cert_count;
                this.certStats[2].val = res.data.used_credit;
                this.certStats[3].val = res.data.see_count;
                this.reportChart();
                this.certificateChart();
          })
          getBase({}).then( res => {
              this.banners = res.data.banner;
          })
      },  
      initTableData(){
          let apiData = {
            per_page:4,page:1
          }
          branchCredit({}).then( res => {
              this.branchCreditData = res.data;
          });
          membersCertApplies({apiData}).then( res => {
              this.tableData = res.data;
          });
          certsList({apiData}).then( res => {
              this.tableRecent = res.data;
          });
          branchNotifications({apiData}).then( res => {
              this.notifications = res.data;
          });
      },
      periodChange(){
          this.initPageData();
      },
      handleClick(row) {
        console.log(row);
      },
      certificateChart(){
        this.$root.echarts.dispose(document.getElementById("certificate"));
        let certificateChart = this.$root.echarts.init(
            document.getElementById("certificate")
        );
        certificateChart.setOption({
            color: ['#008FD7'],
            title: { text: this.$t('data.chart.bestIssueCertificate'), left: 'center'},
            legend: {
                x:'center',
                y:'bottom',
                data: [this.$t('data.chart.numberIssues')]
            },
            tooltip: {},
            xAxis: {
            },
            yAxis: {
                inverse: true,
                data: this.certificateChartData.name,
            },
            series: [
                {
                name: this.$t('data.chart.numberIssues'),
                type: "bar",
                data: this.certificateChartData.count,
                label: {
                    show: true,
                    position: 'right',
                    valueAnimation: true
                }
                },
            ],
        });
      },
      reportChart(){
        this.$root.echarts.dispose(document.getElementById("report"));
        let reportChart = this.$root.echarts.init(
            document.getElementById("report")
        );
        reportChart.setOption({
            color: ['#008FD7'],
            title: { text: this.$t('data.chart.branchIssueReport'), left: 'center' },
            legend: {
                x:'center',
                y:'bottom',
                data: [this.$t('data.chart.numberIssues')]
            },
            tooltip: {},
            xAxis: {
                
            },
            yAxis: {
                inverse: true,
                data: this.reportChartData.name,
            },
            series: [
                {
                name: this.$t('data.chart.numberIssues'),
                type: "bar",
                data: this.reportChartData.count,
                label: {
                    show: true,
                    position: 'right',
                    valueAnimation: true
                }
                },
            ],
        });
      },
      yearlyChart(){
        this.$root.echarts.dispose(document.getElementById("yearly"));
        let yearlyChart = this.$root.echarts.init(
            document.getElementById("yearly")
        );
        yearlyChart.setOption({
            color: ['#008FD7'],
            title: { text: this.$t('data.chart.yearlyIssues'), left: 'center' },
            legend: {
                x:'center',
                y:'bottom',
                data: [this.$t('data.chart.numberIssues')]
            },
            tooltip: {},
            xAxis: {
                data: this.yearCertData.name,
            },
            yAxis: {},
            series: [
                {
                name: this.$t('data.chart.numberIssues'),
                type: "bar",
                data: this.yearCertData.count,
                label: {
                    show: true,
                    position: 'top',
                    valueAnimation: true
                }
                },
            ],
        });
      },
      toMore(){
        //   let apiData = {}
        //   getHome({apiData}).then( res => {
        //       console.log(res)
        //   })
        this.$router.push('/notification/list');
      },
        overallClick(row){
            if(row){
                this.$router.push('/ecert/application-detail/'+row.id);
            }
        },
        toLink(val){
            this.$router.push(val);
        },
        excelExport(){
            let apiData = {
                time_type: this.period
            }
            membersExport({apiData}).then( res => {
                window.open(res.data.url);
            })
        }
    }
}
</script>

<style>
.item-card-data{
    height: 150px;
    width: 100%;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}
.item-card-data .grid-cont{
    text-align: center;
}
.item-card-data .grid-num{
    font-size: 30px;
    font-weight: 700;
    color: #008FD7;
    line-height: 50px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.credits-card{
    display: inline-flex;
    align-items: center;
    width: 100%
}

.credits-card-text{
    width: 100%;
}
.credits-card-text p{
    margin-bottom: 10px;
}
.credits-card-img{
    width: 100%;
    height: 150px;
}
.credits-card-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.notifications-list{
    padding: 10px 50px;
    height: 268px;
    overflow-y: auto;
}
.notifications-list ul li{
    line-height: 35px;
    margin-bottom: 15px;
    color: #868e96;
}
.notifications-list-text{
    color: #606266;
    font-size: 14px;
    font-weight: 400 !important;
}
.item-chart{
    position: relative;
    margin: 30px 0px;
}
</style>